<template>
    <div class="m-1 mt-2 pt-2" style="border-bottom: 2px solid #29B6F6; font-size: 0.75rem">
        <div class="d-flex" style="vertical-align: middle;">
            <div class="p-1 d-flex col-4">
                <span class="p-1 breadcrumb-full" style="border-radius: 0.4rem; color: white; background-color: #6D4F6D;">
                    <span class="breadcrumb-tags">Company:</span> Apollo
                </span>
                <span class="d-flex my-auto breadcrumb-full">
                    <span class="" style="border-top: 2px solid #29B6F6; width: 10px; height: 1px; margin: 0"></span>
                </span>
                <span class="p-1 breadcrumb-full" style="border-radius: 0.4rem; color: black; border: 1px solid #C5AFC5">
                    <span class="breadcrumb-tags">Calendar Year:</span> 2024-2025
                </span>
                <span class="d-flex my-auto breadcrumb-full">
                    <span class="" style="border-top: 2px solid #29B6F6; width: 10px; height: 1px; margin: 0"></span>
                </span>
                <span class="breadcrumb-full">
                    <i class="bi bi-hexagon planning-hex" v-show="!objective_viewing_quarter" data-content="" style="color: white" ></i>
                    <i class="bi bi-hexagon planning-hex" v-show="objective_viewing_quarter == 1" data-content="Q1" :style="objective_viewing_quarter == 1 ? 'color: white' : ''" ></i>
                    <i class="bi bi-hexagon planning-hex" v-show="objective_viewing_quarter == 2" data-content="Q2" :style="objective_viewing_quarter == 2 ? 'color: white' : ''" ></i>
                    <i class="bi bi-hexagon planning-hex" v-show="objective_viewing_quarter == 3" data-content="Q3" :style="objective_viewing_quarter == 3 ? 'color: white' : ''" ></i>
                    <i class="bi bi-hexagon planning-hex" v-show="objective_viewing_quarter == 4" data-content="Q4" :style="objective_viewing_quarter == 4 ? 'color: white' : ''" ></i>
                    <i class="bi bi-hexagon planning-hex" v-show="objective_viewing_quarter == 5" data-content="H1" :style="objective_viewing_quarter == 5 ? 'color: white' : ''" ></i>
                    <i class="bi bi-hexagon planning-hex" v-show="objective_viewing_quarter == 6" data-content="H2" :style="objective_viewing_quarter == 6 ? 'color: white' : ''" ></i>
                    <i class="bi bi-hexagon planning-hex" v-show="objective_viewing_quarter == 7" data-content="YR" :style="objective_viewing_quarter == 7 ? 'color: white' : ''" ></i>
                </span>
                <span class="d-flex my-auto breadcrumb-full">
                    <span class="" style="border: 1px solid #29B6F6; width: 10px; height: 1px; margin: 0"></span>
                </span>
                <span class="d-flex breadcrumb-full">
                    <span class="angle-line" style=""></span>
                </span>
                <!-- <div class="line line1"></div> -->
            </div>
            <div  class="mb-1 d-flex col-3 justify-content-center">
                <span style="font-size: 18px;">
                    Business Planning
                </span>
            </div>
            
            <div  class="p-1 d-flex col-5 justify-content-end">
                <span class="btn-group me-2 mt-1 d-flex justify-content-center" style="height: 22px; border-radius: 0.2rem; background-color: #A27FA2;">
                    <!-- <span size="sm" title="Flat Structure" style="color: #A27FA2 !important;" > -->
                        <span class="left-toggle-filter ps-1 pe-1" v-b-tooltip.hover.bottomright style="background-color: rgb(41, 182, 246) !important; margin: 2px">
                            Flat Structure
                        </span>
                       
                    <!-- </span> -->
                    <!-- <span size="sm" title="Direct Childeren" style=" color: #A27FA2 !important; background-color: #A27FA2;"> -->
                        <span class="right-toggle-filter ps-1 pe-1" style="margin: 2px" v-b-tooltip.hover.bottomright>
                            Direct Childeren
                        </span>
                    <!-- </span> -->
                </span>
                <span class="m-1 me-2 d-flex justify-content-center">
                    <span>
                        List Mode <font-awesome-icon class="ms-1" :icon="['fas', 'bars']" />
                    </span>
                </span>
                <notificationMenu />
            </div>
        </div>
    </div>
</template>

<script>
import {
    levelMethods,
    levelComputed,
    activityMethods,
    activityComputed,
    performanceComputed,
    performanceMethods,
    ganttComputed,
    ganttMethods

} from "@/state/helpers";
//import _ from 'lodash';
import notificationMenu from "../../menu/notificationMenu.vue";
import miletoneHelpers from '@/mixins/milestoneHelpers.js'
export default {
    mixins: [ miletoneHelpers ],
    components:{
        notificationMenu
    },
    data:() => ({}),
    computed:{
        ...levelComputed,
        ...activityComputed,
        ...performanceComputed,
        ...ganttComputed,
    },
    watch:{
    },
    methods:{
        ...levelMethods,
        ...activityMethods,
        ...performanceMethods,
        ...ganttMethods,
    },
    mounted(){
    },
}
</script>

<style>
.planning-hex::before {
    display: inline-flex;
    justify-content: center;
    vertical-align: middle;
    content: attr(data-content);
    padding-top: 5px;
    padding-right: 0px;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='38' height='38' fill='%23736BA5' stroke='%2350A5F1' stroke-width='0.8' class='bi bi-hexagon-fill' style='transform: rotate(90deg)' viewBox='0 0 17 16'><path fill-rule='evenodd' d='M8.5.134a1 1 0 0 0-1 0l-6 3.577a1 1 0 0 0-.5.866v6.846a1 1 0 0 0 .5.866l6 3.577a1 1 0 0 0 1 0l6-3.577a1 1 0 0 0 .5-.866V4.577a1 1 0 0 0-.5-.866z'/></svg>");
    background-repeat: no-repeat;
    background-size: 2rem 2rem;
    font-style: initial;
    width: 32px;
    height: 30px
}
/* .angle-line {
    border-radius: 50%;
    border-top: 1px solid #29B6F6; 
    border-right: 1px solid #29B6F6; 
    position: relative;
    width: 30px; 
    height: 30px; 
    margin: 0
}
.angle-line:before {
    position:absolute;
    width: 10px !important; 
    height: 10px !important; 
} */
.left-toggle-filter {
    border-top-left-radius: 0.2rem; 
    border-bottom-left-radius: 0.2rem; 
    font-size: 0.7rem; 
    color: white;
}
.right-toggle-filter {
    border-top-right-radius: 0.2rem; 
    border-bottom-right-radius: 0.2rem; 
    font-size: 0.7rem; 
    color: white;
}
.angle-line {
    border-radius:30px 0 0 0 ;
    border-width: 2px 0 0 2px;
    /* margin-left:100px; */
    rotate: 90deg;
    margin-top: 20px;
    margin-left: -7px;
    border-color:#29B6F6;
    border-style: solid;
    height:8px;
    width: 20px;
}
@media(max-width: 1500px) {
    .breadcrumb-tags {
        display: none;
    }
}
/* @media(max-width: 1280px) {
    .breadcrumb-full {
        display: none !important;
        visibility: hidden !important; 
    }
} */
</style>