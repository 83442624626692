<script>
import Layout from "../../layouts/main";
import planning from '@/components/planning/planning.vue'
// import OrganisationChart from '@/components/widgets/graphs/organisation-chart.vue'
import {menuMethods, levelComputed, activityMethods, activityComputed, adminComputed, profileComputed, profileMethods} from '@/state/helpers'
/**
 * Dashboard Component
 */
export default {
    page: {
        title: "planning",
    },
    components: {
        Layout,
        // PerformanceView,
        // OrganisationChart
        planning
    },
    data() {
        return {
            items: [
                {
                    text: "Home",
                    href: "/",
                },
                {
                    text: "Planning",
                    active: true,
                },
            ],
        };
    },
    computed:{
        ...levelComputed,
        ...adminComputed,
        ...activityComputed,
        ...profileComputed,
        hasLoadedCompanyMilestones(){
            return this.company_milestones != null;
        },
        hasRequiredPermissions(){
            if(this.$hasPerm('performance') || this.$hasPerm('performance-manager')){
                return true;
            }
            return false;
        },
        title(){
            if(this.isTopLevel){
                return 'Company Planning';
            }
            else{
                return 'Planning'
            }
        },
        isTopLevel(){
            let valid = true;
            if(this.selected_level != null){
                if(this.selected_level.parent_id != null){
                    valid = false;
                }
            }
            return valid;
        },
        viewType(){
            if(this.selected_user_id != null){
                return 'user_view'
            }
            else{
                if(this.isTopLevel){
                    return 'company_view'
                }
                else{
                    return 'department_view'
                }
            }
        }
    },
    methods:{
        ...menuMethods,
        ...activityMethods,
        ...profileMethods
    },
    mounted() {
        //*set objective id from local storage
        if(window.localStorage.getItem('objective_id') != null){
            this.$store.commit('activity/setInitialObjectiveId', Number(window.localStorage.getItem('objective_id')));
            window.localStorage.removeItem('objective_id')
        }

        //* set milestone_id from local storage
        if(window.localStorage.getItem('milestone_id') != null){
            this.$store.commit('activity/setPinnedMilestone', Number(window.localStorage.getItem('milestone_id')));
        }
        
        //*used to display loading state in router menu
        this.getKeyResultUnitAPI()
        .then(()=>{
            setTimeout(()=>{
                this.setLoading('false');
            }, 500)
        })
    },
    beforeDestroy(){
        // this.hideOrgChart(false)
        //this.clearData();
        //this.clearCompanyMilestones();
    }
};
</script>

<template>
    <Layout>
        <div v-if="hiddenOrgChart">
            <!-- <OrganisationChart /> -->
        </div>
        <div v-if="hasRequiredPermissions == false && !hiddenOrgChart">
                <b-alert show >Permission not found</b-alert>
        </div>
        <div v-if="hasLoadedCompanyMilestones && hasRequiredPermissions && !hiddenOrgChart" class="">
            
            <!-- <transition-group name="fade">
                <PerformanceView key="performanceView" v-if="has_loaded_milestones" />
                <div key="performanceLoadingSpinner" v-else>
                    <b-card no-header  class="mt-4 ms-4 me-4 pt-4 pb-4">
                        <div style="">
                            <div class="text-center mb-4">
                                <b-spinner class="" variant="primary" label="Spinning"></b-spinner>
                            </div>
                            <div class="text-center">
                                <h5>Loading Milestone Data...</h5>
                            </div>
                            
                        </div>
                    </b-card>
                </div>
            </transition-group> -->
            <planning />
        </div>
    </Layout>
</template>
