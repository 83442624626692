<template>
    <div>
      <div v-if="mounted">
        <Swiper
        v-if="mounted"
        id="planningSwiper"
        :slides-per-view="6"
        :space-between="0"
        :loop="false"
        :pagination="{
            el: '.' + pagination,
            clickable: true,
        }"
        :breakpoints="{
          0: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          1336: {
            slidesPerView: 3,
            spaceBetween: 0
          },
          1846: {
            slidesPerView: 4,
            spaceBetween: 0
          },
          2200: {
            slidesPerView: 4,
            spaceBetween: 0
          }
        }"
        :navigation="{
            nextEl: '.' + right,
            prevEl: '.' + left,
        }"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <SwiperSlide
          v-for="item in data"
          :key="item.id"
        >
           <objectivesCard :item="item" />
        </SwiperSlide>
      </Swiper>
      
      <div class="carousel__controls">
          <font-awesome-icon :class='["planning-button-prev", left, "cursor-pointer"]' icon="fa-angle-left"></font-awesome-icon>
          <div id="planning" :class='[pagination]'> </div>
          <font-awesome-icon :class='["planning-button-next", right, "cursor-pointer"]' icon="fa-angle-right"></font-awesome-icon>
      </div>
      </div>
    </div>
  </template>
  
  <script>
  import { Navigation, Pagination } from 'swiper'
  
  import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
  import 'swiper/swiper-bundle.css'
  import objectivesCard from './objectivesCard.vue';
  
  SwiperCore.use([Navigation, Pagination])
  
  export default {
    props: {
      id: {
        required: true
      },
      data: {
        required: true
      },
      type: {
        required: true
      }
    },
    components: {
      Swiper,
      SwiperSlide,
      objectivesCard
    },
    computed: {
    },
    methods: {
      onSwiper (swiper) {
        console.log(swiper)
      },
      onSlideChange () {
        console.log('slide change')
      },

    },
    data: () => ({
      value: 5,
      mounted: false,
      left: 'left',
      right: 'right',
      pagination: 'pagination'
    }),
    mounted() {
      this.left = 'custom-' + this.id + '-left'
      this.right = 'custom-' + this.id + '-right'
      this.pagination = 'custom-' + this.id + '-pagination'
      //setTimeout(() => {
        this.mounted = true
      //}, 10)
    }
  }
  </script>
  
  <style lang="scss">
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  .w-100 {
    width: 100%;
  }
  .ml-auto, .mx-auto {
    margin-left: auto;
  }
  .mr-auto, .mx-auto {
    margin-right: auto;
  }
  .document_card_styles {
    border: 1px solid grey;
    border-radius: 0.2rem;
    height: 100px
  }
  .carousel {
    &__controls {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        margin-top: 1px;
    }
  }
  .planning-button-prev,
  .planning-button-next {
    height: 24px;
    width: 18px;
  }
  
  #planning .swiper-pagination {
    // width: unset;
    position: unset;
    display: flex;
    width: 6px;
    height: 6px;
    margin-top: 6px
  }

  #planning .swiper-pagination-bullets {
    padding-top: 2px;
    padding-left: 2px;
    padding-right: 2px
  }
  
  #planning .swiper-pagination-bullet {
    margin: 2px !important;
    width: 7px;
    height: 7px
  }
  </style>