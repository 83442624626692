<template>
    <div class="w-100" :id="config.id">
        <div class="component">
            <div class="m-1 d-flex" @click="toggleView()" style="height: 34px"><!-- header -->
                <div class="d-flex my-auto w-100" style="flex-direction:column; color: #A27FA2">
                    <div class="d-flex my-auto w-100">
                        <div class="my-auto" style="display: flex; flex-direction: row; white-space: nowrap;">
                            <div v-if="settings.is_expanded" class="m-1 mt-1"><font-awesome-icon :icon="['fas', 'circle-chevron-down']" /> </div>
                            <div v-if="!settings.is_expanded" class="m-1 mt-1"><font-awesome-icon :icon="['fas', 'circle-chevron-right']" /> </div>
                            <div style="font-size: 18px; ">{{ config.name }}</div> 
                            <div class="m-1 mt-1" v-if="config.id != 1" style="font-size: 12px"> ({{data.length}})</div>
                        </div>
                    
                        <span class="d-flex my-auto mx-auto" style="width: 100%; position: relative">
                            <span :class="config.id == 1 ? 'm-1 w-100' : 'w-100'" style="border: 1px solid #ACACAC;height: 1px; margin: 0; overflow: hidden;"></span>
                        </span>
                    </div>

                    <div class="d-flex my-auto w-100" v-if="config.id != 1 && !settings.is_expanded" style="justify-content: center;">
                        <div class="d-flex" style="flex-direction: row; position: absolute; min-width: 300px; color: white; font-size: 0.7rem;">
                            <span class="p-1 ps-2 pe-2 left_tag mx-auto w-100 justify-content-center d-flex">
                                <span>00</span></span>
                            <span class="p-1 ps-2 pe-2 middle_tag mx-auto w-100 justify-content-center d-flex">
                                <span>00</span></span>
                            <span class="p-1 ps-2 pe-2 right_tag mx-auto w-100 justify-content-center d-flex">
                                <span>00</span></span>
                        </div>
                    </div>
                </div>
                
            </div>
            <b-collapse v-model="settings.is_expanded">
                <div v-if="config.id == 1">
                    <planningVisionAndMisison />
                </div>


                <div class="m-1 w-100" v-if="config.id != 1" style="min-height: 100px;">
                    <b-overlay class="mt-1" :show="!loaded"> 
                    <planningSwiper v-if="settings.is_expanded && data.length > 0" :id="config.id" :type="config.name" :data="data" />
                    </b-overlay>
                    <!-- <span v-if="data.length == 0">
                        <b-alert show variant="primary">No Data</b-alert>
                    </span> -->
                </div>
                
            </b-collapse>

            <!-- for objectives where there are children - use component recursively -->
            <collapseComponent v-if='false' title_prop :items="[{id: 1, value: 'test'},{id: 2, value: 'test'},{id: 2, value: 'test'}]"/>
        </div>
        
    </div>
</template>

<script>
// import './treeTable.css'
import _ from 'lodash'
import collapseComponent from '../planning/collapseComponent.vue'
import { treeComponentMethods, treeComponentComputed, activityComputed, levelComputed, performanceComputed } from '@/state/helpers';
import planningSwiper from './components/planningSwiper.vue';
import planningVisionAndMisison from './components/planningVisionAndMisison.vue';
export default {
    name: 'Renderer',
    components:{
        collapseComponent,
        planningSwiper,
        planningVisionAndMisison
    },
    data:() => ({
        loaded: false,
        settings:{
            id: null,
            is_expanded: false,
            selected_id: -1,
        },
        is_dragingg: false,
        local_config: {},
        local_mapped_data:[],
        showSlideContainer: false,
        data: []
    }),
    props:{
        // depth: {
        //     default: () => { return 0; }
        // },
        config:{
            required: true,
        },
    },
    watch:{
        goal_objectives() {
            this.getData()
            this.loaded = true
        },
        milestone_goals() {
            this.getData()
            this.loaded = true
        }
    },
    computed:{
        ...treeComponentComputed,
        ...activityComputed,
        ...levelComputed,
        ...performanceComputed
    },
    methods:{
        ...treeComponentMethods,
        toggleView() {
            this.settings.is_expanded = !this.settings.is_expanded
            this.$emit('update', this.settings)
        },
        getData() {
            console.log("SETTING THE DATA")
            switch(this.config.id){
                case 2:{
                    this.data = _.cloneDeep(this.goal_objectives)
                    break;
                }
                case 3:{
                    this.data = _.cloneDeep(this.milestone_goals)
                    break;
                }
            }
        }
        // isRowSelected(id){
        //     if(!this.config.enable_row_selection){
        //         return;
        //     }
        //     return id == this.settings.selected_id ? 'item_row_active' : ''
        // },
    },
    mounted(){
        this.settings.is_expanded = this.config.is_expanded
        this.settings.id = this.config.id
        //this.getData()
    },
    beforeDestroy(){
    }
}
</script>

<style>
.left_tag {
    margin-top: -25px;
    background-color: #F46A6A;
    border-top-left-radius: 0.2rem; 
    border-bottom-left-radius: 0.2rem;
    height: 23px
}
.middle_tag {
    margin-top: -25px;
    background-color: #FFC107;
    height: 23px
}
.right_tag {
    margin-top: -25px;
    background-color: #4CAF50;
    border-top-right-radius: 0.2rem; 
    border-bottom-right-radius: 0.2rem;
    height: 23px
}
</style>