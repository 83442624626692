
<template>
    <div class="d-flex flex-wrap justify-content-center item">
      <!-- <b-overlay> -->
            <div :class="selected_objectives.includes(item.id) ? 'selected_card planning_card_container d-flex flex-column' : 'planning_card_container d-flex flex-column'" @click="setSelected(item.id)" style="background-color: white;">
              <div class="p-1 m-1">
                <div class="d-flex" style="flex-direction: column">
                    <div class="d-flex">
                        <div class="avatar-sm me-3">
                        <span class="avatar-title bg-secondary bg-soft rounded-circle font-size-16">
                            <b-img
                            style="min-width: 48px !important; height: 48px !important; padding: 2px;"
                                fluid
                                :src="getUserImg"
                                alt
                                onerror="src='/img/user.png'"
                                class=" smaller-image-height avatar-md rounded-circle header-profile-user-view"
                                id="image"
                                >
                            </b-img>
                        </span> 
                        </div>
                        <div style="font-size: 0.7rem;">
                        {{ item.name }}
                        </div>
                    </div>
                    <div class="d-flex w-100" style="flex-direction: row; justify-content: space-between;">
                        <div class="d-flex">
                            <span class="m-1 p-1 pt-0 pb-0 ms-auto badge-card" >
                                SRC?
                            </span>
                            <span class="m-1 p-1 pt-0 pb-0 ms-auto badge-card" >
                                TYP?
                            </span>
                            <span class="m-1 p-1 pt-0 pb-0 ms-auto badge-card" >
                                DUR?
                            </span>
                            <span class="m-1 p-1 pt-0 pb-0 ms-auto badge-card" >
                                PRY?
                            </span>
                        </div>
                        <div class="d-flex">
                            <span class="m-1 p-1 pt-0 pb-0 ms-auto badge-notification" >
                                00
                            </span>
                            <span class="m-1 p-1 pt-0 pb-0 ms-auto badge-notification" >
                                <div class="d-flex" style="flex-direction: row">
                                    <span>
                                        <font-awesome-icon class="fa-fw" icon="fa-solid  fa-bell" style="" />
                                    </span>
                                    <span>
                                        00
                                    </span>
                                </div>
                               
                            </span>
                        </div>
                           
                        
                    </div>
                    <div class="w-100" style="height: 16px; background-color: yellow;">

                    </div>
                    <div class="d-flex w-100" style="flex-direction: row;">
                            <b-button :disabled="childrenLength == 0" :variant="isCurrentItemInExpandedList ? 'primary' :'light'" size="sm" @click="toggleChildren($event)" class="m-1 pt-0 pb-0 ms-auto" style="width: 100%" >
                                    <span v-show="childrenLength > 0" > OBJs ({{ childrenLength }})</span>
                                    <span v-show="childrenLength == 0" > OBJs (0)</span>
                            </b-button>
                        
                    
                            <b-button :disabled="childrenLength == 0" :variant="isCurrentItemInExpandedList ? 'primary' :'light'" size="sm" @click="toggleChildren($event)" class="m-1 pt-0 pb-0 ms-auto" style="width: 100%" >
                                    <span v-show="childrenLength > 0" > KRs ({{ childrenLength }})</span>
                                    <span v-show="childrenLength == 0" > KRs (0)</span>
                            </b-button>
                        
                    
                            <b-button :disabled="childrenLength == 0" :variant="isCurrentItemInExpandedList ? 'primary' :'light'" size="sm" @click="toggleChildren($event)" class="m-1 pt-0 pb-0 ms-auto" style="width: 100%" >
                                    <span v-show="childrenLength > 0" > TSKs ({{ childrenLength }})</span>
                                    <span v-show="childrenLength == 0" > TSKs (0)</span>
                            </b-button>
                    </div>

                    <!-- <font-awesome-icon class="ms-auto" :icon="['fas', 'angle-right']" @click="$emit('handleSelect', item.pivot.user_id, item.jobs[0].job_id)" style="height: 20px !important; width: 20px !important; margin-top: 5px; color: #CB6CC3" /> -->
                    <!-- <b-dropdown text="Role">
                      <b-dropdown-item @click="$emit('handleSelect', item.id, job.id)" v-for="job in item.jobs" :key="job.id">
                          {{ job.name }}
                      </b-dropdown-item>
                  </b-dropdown> -->
            
                    <!-- <div @click="handleScorecardClick(item.id)" style="width: 30px; height: 30px; margin-right: -10px; margin-top: -10px;" class="ms-auto bg-secondary">
                    </div> -->
                </div>
                <div class="d-flex ms-1" style="flex-direction: row;">
                      <div class="col-12 mt-1">
                      </div>
                      <div class="col-6 ">
                          <div class="col-3 d-flex w-100" style="flex-direction: column;">
                              
                          </div>
                      </div>
                    </div>
                </div>
            </div>
        <!-- </b-overlay> -->
    </div>
</template>

<script>
import treeComponentMixin from '@/mixins/treeComponentMixin.js'
import { levelComputed, activityComputed, scorecardComputed, scorecardMethods, planningComputed, planningMethods } from '@/state/helpers';
import _ from 'lodash'
export default {
  name: 'objectivesCard',
  mixins: [ treeComponentMixin ],
  props: {
      item: {
          required: true
      },
      loading: {
          default: false
      }
  },
  data:() => ({   
    childrenLength: 1 ,
    isCurrentItemInExpandedList: false
  }),
  computed:{
    ...levelComputed,
    ...activityComputed,
    ...scorecardComputed,
    ...planningComputed,
    getDisabledState(){
        if(!this.hasYearSelected || !this.hasValidPeriodSelected){
            return 'bg-secondary bg-soft disabled-cursor';
        }
        return '';
    },
    hasYearSelected(){
        return this.selected_milestone != null;
    },
    hasValidPeriodSelected(){
        return this.objective_viewing_quarter != 0;
    },
    getUserImg() {
        let item_owner = this.all_users.find( user => {
            return user.id == this.item.owner_user_id;
        });
        if(item_owner) {
            return item_owner.avatar_src
        }
        return ''
    }
  },
  methods:{
    ...scorecardMethods,
    ...planningMethods,
    getDivision(item) {
      let level = _.find(this.levels, {
          id: item.level_id,
      });
      return level.name
    },
    setSelected(id) {
        let selected = _.cloneDeep(this.selected_objectives)
        let array = selected
        if(array.length != 0 && array.includes(id)) {
            // remove item
            let idx = _.indexOf(array, id);
            array.splice(idx, 1)
        } else {
            // add item
            array.push(Number(id))
        }
        this.setSelectedPlanningObjectives(array)
    },
    getColor(item) {
      if (item > 6) {
      return 'quarter_high'
      } else if (item > 3) {
      return 'quarter_medium'
      } else if (item > 0) {
      return 'quarter_low'
      }
      return ''
    },
  //   getReportsTo(item) {
  //     let id = item.report_to_user_id
  //     let user_object = this.all_users.find((user) => {
  //         return user.id == id;
  //     });
  //     return user_object.name
  //   },
  },
  created() {
  },
  beforeDestroy() {
  },
}
</script>


<style>
.item {
  display: inline-flex;
  margin: 5px;
  /* height: 233px; */
  /* width: 340px; */
  /* border: 1px solid black; */
  border-radius: 0.2rem;
}

.planning_card_container {
    width: 365px;
    height: 130px;
    border: 1px solid rgb(187, 187, 187);
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 0.55rem;
    border-bottom-left-radius: 0.55rem;
    border-bottom-right-radius: 0.55rem;
}
.badge-notification {
    width: 100%; 
    background-color: grey; 
    border-radius: 0.2rem; color: white; 
    font-weight: bold;
}
.badge-card {
    width: 100%; 
    background-color: #7F94A6; 
    border-radius: 0.2rem; 
    color: white; 
    font-weight: bold;
}
.selected_card {
    border: 2px solid rgb(41, 182, 246)
}
</style>