<template>
    <div class="w-100 mt-2">
        <!-- <b-button @click="insertChildren">Insert</b-button> -->
         <div class="d-flex my-auto" style="height: 88px">
            <div class="my-auto" style="font-size: 24px ;color: #55666f">Business Planning</div>
            <div class="my-auto ms-2">You are viewing the business planning of -</div>
         </div>
         <span v-if="loaded">
            <b-overlay :show="!loaded">
            <span v-for="(item) of collapseComponents" :key="item.id">
                <CollapseComponent :config="item" @update="updateLocalStorage" />
            </span>
            </b-overlay>
         </span>
        
        <!-- <Renderer key="qualifiers" :config="qualifiers_config" @setView="setView" /> -->
    </div>
</template>

<script>
import CollapseComponent from './collapseComponent.vue';
export default {
    components:{
        CollapseComponent,
    },
    watch: {
    },
    data:() => ({
        loaded: false,
        collapseComponents: [
            {id: 1, name: 'Vision & Mission', is_expanded: false},
            {id: 2, name: 'Goals', is_expanded: false},
            {id: 3, name: 'Objectives', is_expanded: false},
            {id: 4, name: 'Key Results', is_expanded: false},
            {id: 5, name: 'Projects', is_expanded: false},
            {id: 6, name: 'Tasks', is_expanded: false},
        ]
    }),
    computed:{

    },
    methods:{
        //helper methods
        updateLocalStorage(settings) {
            window.localStorage.removeItem('planning');
            let index = settings.id - 1
            this.collapseComponents[index].is_expanded = settings.is_expanded
            // this.collapseComponents[index].item = item
            console.log(JSON.parse(JSON.stringify(this.collapseComponents)))
            this.$nextTick(() => {
                window.localStorage.setItem('planning', JSON.stringify(this.collapseComponents));
            })
        }
    },
    mounted(){
        let value = window.localStorage.getItem('planning')
        console.log(JSON.parse(JSON.stringify(value)))
        if(value != null) {
            this.collapseComponents = JSON.parse(value)
        } else {
            window.localStorage.setItem('planning', JSON.stringify(this.collapseComponents));
        }
        this.loaded = true
    },
}
</script>

<style>
</style>