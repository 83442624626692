<template>
    <div class="w-100">
        <div v-if="view_type == 'COMPANY'">
            <b-row class="mb-2">
                <b-col class="align-items-stretch" cols="6">
                    <b-card class="card-hover h-100">
                        <Vision @reloadData="loadPerfromanceData()" :data="vision" :title="selected_level.name"  />
                    </b-card>
                </b-col>
                <b-col class="align-items-stretch" cols="6">
                    <b-card class="card-hover h-100">
                        <Mission @reloadData="loadPerfromanceData()" :data="mission" :title="selected_level.name" />
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import { treeComponentMethods, treeComponentComputed, activityComputed, levelComputed } from '@/state/helpers';
import Vision from '../../objectives/components/cards/vision.vue';
import Mission from '../../objectives/components/cards/mission.vue';
export default {
    name: 'Renderer',
    components:{
        Vision,
        Mission,
    },
    data:() => ({
    }),
    watch:{
    },
    computed:{
        ...treeComponentComputed,
        ...activityComputed,
        ...levelComputed,
        view_type(){
            if(this.selected_user_id != null){
                return 'USER'
            }
            else if(this.current_level_id == this.company_id){
                return 'COMPANY'
            }
            else{
                return 'DIVISION'
            }
        },
        vision() {
            var vision = "";
            if (this.activity_data && this.activity_data.vision) {
                vision = this.activity_data.vision;
            }
            return vision;
        },
        mission() {
            var mission = "";
            if (this.activity_data && this.activity_data.mission) {
                mission = this.activity_data.mission;
            }
            return mission;
        },
    },
    methods:{
        ...treeComponentMethods,
    },
    mounted(){
        
    },

}
</script>

<style>
</style>