var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.mounted)?_c('div',[(_vm.mounted)?_c('Swiper',{attrs:{"id":"planningSwiper","slides-per-view":6,"space-between":0,"loop":false,"pagination":{
        el: '.' + _vm.pagination,
        clickable: true,
    },"breakpoints":{
      0: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      1336: {
        slidesPerView: 3,
        spaceBetween: 0
      },
      1846: {
        slidesPerView: 4,
        spaceBetween: 0
      },
      2200: {
        slidesPerView: 4,
        spaceBetween: 0
      }
    },"navigation":{
        nextEl: '.' + _vm.right,
        prevEl: '.' + _vm.left,
    }},on:{"swiper":_vm.onSwiper,"slideChange":_vm.onSlideChange}},_vm._l((_vm.data),function(item){return _c('SwiperSlide',{key:item.id},[_c('objectivesCard',{attrs:{"item":item}})],1)}),1):_vm._e(),_c('div',{staticClass:"carousel__controls"},[_c('font-awesome-icon',{class:["planning-button-prev", _vm.left, "cursor-pointer"],attrs:{"icon":"fa-angle-left"}}),_c('div',{class:[_vm.pagination],attrs:{"id":"planning"}}),_c('font-awesome-icon',{class:["planning-button-next", _vm.right, "cursor-pointer"],attrs:{"icon":"fa-angle-right"}})],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }